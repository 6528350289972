import { Upload } from "lucide-react";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";

interface props {
  phaseNumber: number;
  setPhaseNumber: Function;
  phaseType: string;
  getData: Function;
}

const CreatePhases = ({
  phaseNumber,
  setPhaseNumber,
  phaseType,
  getData,
}: props) => {
  const [phaseData, setphaseData] = useState({
    start_date: "",
    end_date: "",
    description: "",
    image: "",
    dateCount: 1,
  });
  const [selectedImage, setSelectedImage] = useState<any>("");
  const [phaseStartDate, setphaseStartDate] = useState(false);
  const [phaseEndDate, setphaseEndDate] = useState(false);
  const [phaseDescError, setphaseDescError] = useState(false);

  const ValidatePhaseInput = () => {
    // if (phaseData.start_date == "") {
    //   setphaseStartDate(true);
    // } else if (phaseData.end_date == "") {
    //   setphaseEndDate(true);
    // } else 
    if (phaseData.description == "") {
      setphaseDescError(true);
    } else {
      getData(phaseData);
      setPhaseNumber(phaseNumber + 1);
      setphaseData({
        start_date: "",
        end_date: "",
        description: "",
        image: "",
        dateCount: 1,
      });
      setSelectedImage("");
    }
  };

  const handlePhaseInput = (field: any, e: string) => {
    setphaseData((prev) => ({
      ...prev,
      [field]: e,
    }));
  };

  const handleUpload = (file: any) => {
    setphaseData((prev) => ({
      ...prev,
      ["image"]: file,
    }));
  };

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      handleUpload(file);
      e.target.value = "";
    }
  };

  return (
    <div className="mx-auto bg-white  rounded-lg shadow-md p-6">
      <h2 className="text-xl font-semibold mb-4">Create Phases</h2>
      <h3 className="font-semibold">Phase {phaseNumber}</h3>
      <div className="space-y-2">
        <div className="flex items-center space-x-4">
          <div className="flex-grow">
            <label
              htmlFor="phaseName"
              className="block text-sm font-medium text-gray-700"
            >
              Phase name<label className="text-red-600">*</label>
            </label>
            <input
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-1 px-2"
              placeholder="Enter phase name"
              disabled
              value={phaseType}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label
              htmlFor="startDate"
              className="block text-sm font-medium text-gray-700"
            >
              Start date
            </label>
            <div className="mt-1 relative">
              <input
                type="date"
                className="block w-full border border-gray-300 rounded-md shadow-sm p-1 px-2 "
                value={phaseData.start_date}
                // min={new Date().toISOString().split("T")[0]}
                onChange={(e) => {
                  handlePhaseInput("start_date", e.target.value);
                  setphaseStartDate(false);
                }}
              />
              {phaseStartDate && (
                <Validation name={"please enter start date"} />
              )}
            </div>
          </div>
          <div>
            <label
              htmlFor="endDate"
              className="block text-sm font-medium text-gray-700"
            >
              End date
              
            </label>
            <div className="mt-1 relative">
              <input
                type="date"
                className="block w-full border border-gray-300 rounded-md shadow-sm p-1 px-2 "
                value={phaseData.end_date}
                min={phaseData.start_date}
                onChange={(e) => {
                  phaseData.start_date
                    ? (handlePhaseInput("end_date", e.target.value),
                      setphaseEndDate(false))
                    : toast("please select the start date!");
                }}
              />
              {phaseEndDate && <Validation name={"please enter end date"} />}
            </div>
          </div>
        </div>

        <div>
          <label
            htmlFor="description"
            className="block text-sm font-medium text-gray-700"
          >
            Description<label className="text-red-600">*</label>
          </label>
          <textarea
            rows={2}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            placeholder="Write here..."
            value={phaseData.description}
            onChange={(e) => {
              handlePhaseInput("description", e.target.value),
                setphaseDescError(false);
            }}
          />
          {phaseDescError && <Validation name={"please enter description"} />}
        </div>

        <div>
          <label
            htmlFor="uploadImage"
            className="block text-sm font-medium text-gray-700"
          >
            Upload Image
          </label>
          <div className="flex items-center mt-2">
            <label
              htmlFor="uploadImage"
              className="cursor-pointer bg-white border border-gray-300 rounded-md shadow-sm px-4 py-1 inline-flex items-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              <Upload
                className="mr-2 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              {selectedImage?.name ? selectedImage?.name : "Choose file"}
            </label>
            <input
              type="file"
              id="uploadImage"
              className="hidden"
              accept="image/*,application/pdf"
              onChange={handleImageChange}
            />
          </div>
        </div>

        <div className="flex gap-4">
          <button
            type="submit"
            onClick={() => ValidatePhaseInput()}
            className="w-full md:w-auto mt-2 px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
          >
            Next
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CreatePhases;

interface props1 {
  name: string;
}

const Validation = ({ name }: props1) => {
  return <label className="text-[11px] text-red-500">{name}</label>;
};
