import SideDrawer from "../components/SideDrawer";
import CustomHeader from "../components/CustomHeader";
import { MdDashboard, MdAdd } from "react-icons/md";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomerModal from "../components/CustomerModal";
import ApiManager from "../api/ApiManager";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../components/Spinner";
import { BiSolidEdit } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import EditCustomerModal from "../components/EditCustomerModal";
import { ChevronDown, ChevronUp } from "lucide-react";
import DeleteModal from "../components/DeleteModal";

const Dashboard = () => {
  const navigate = useNavigate();

  const [openModal, setopenModal] = useState(false);
  const [customers, setcustomers] = useState<any>([]);
  const [oldData, setoldData] = useState<any>([]);

  const [customerData, setcustomerData] = useState<any>();
  const [apiLoader, setapiLoader] = useState(false);
  const [editUserModel, seteditUserModel] = useState(false);
  const [sortWithName, setsortWithName] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [deleteModal, setdeleteModal] = useState(false);

  const [customerInput, setcustomerInput] = useState({
    name: "",
    email: [],
    number: [],
    company_address: "",
    id: [],
    point_of_contact: [],
    wtsApNumber: [],
    loa : []
  });

  useEffect(() => {
    const disableRightClick = (e: MouseEvent) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", disableRightClick);

    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key === "PrintScreen") {
        navigator.clipboard.writeText("");
        alert("Screenshots disabled!");
      }

      if (e.key === "F11") {
        e.preventDefault();
        alert("Fullscreen disabled!");
      }
    };
    document.addEventListener("keydown", handleKeydown);

    // Cleanup event listeners
    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
      document.removeEventListener("keydown", handleKeydown);
    };
  }, []);

  useEffect(() => {
    getCustomerList();
  }, []);

  const getCustomerList = () => {
    setapiLoader(true);
    ApiManager.getCustomer()
      .then((resp) => {
        if (resp?.data?.status == true) {
          setcustomers(resp?.data?.data);
        } else {
          toast("something went wrong !");
        }
        setapiLoader(false);
      })
      .catch(() => {
        toast("something went wrong !"), setapiLoader(false);
      });
  };

  const saveCustomerData = (data: any) => {
    const pocArray = data?.contactArray.map((e: any) => e.point_of_contact);
    const emailArray = data?.contactArray.map((e: any) => e.email);
    const number = data?.contactArray.map((e: any) => e.number);
    const wtsAppNumber = data?.contactArray.map((e: any) => e.wtsAppNumber);
    const loa = data?.contactArray.map((e: any) => e.loa);

    pocArray.push(data?.customerData?.point_of_contact);
    emailArray.push(data?.customerData?.email);
    number.push(data?.customerData?.number);
    wtsAppNumber.push(data?.customerData?.wtsAppNumber);
    loa.push(data?.customerData?.loa);

    const body = {
      name: data?.customerData?.name?.trim(),
      email: emailArray,
      number: number,
      company_address: data.customerData.company_address.trim(),
      point_of_contact: pocArray,
      watsappNumber: wtsAppNumber,
      loa: loa,
    };

    ApiManager.createCustomer(body)
      .then((resp) => {
        if (resp?.data?.status == true) {
          toast(resp?.data?.message);
          setopenModal(false);
          getCustomerList();
        } else {
          toast("something went wrong !");
          setopenModal(false);
        }
      })
      .catch((error) => toast("server error", error));
  };

  const updateCustmerStatus = (item: number) => {
    const body = {
      id: item,
    };
    ApiManager.updateStatus(body)
      .then((resp) => {
        if (resp?.data?.status == true) {
          getCustomerList();
        } else {
          toast("something went wrong !");
        }
      })
      .catch((error) => toast("server error", error));
  };

  const EditUserDetails = (e: any) => {
    const params = {
      id: customerData?.id,
    };
    const body = {
      name: e?.data?.name?.trim(),
      point_of_contact: e?.data?.point_of_contact,
      email: e?.data?.email,
      number: e?.data?.number,
      watsappNumber: e?.data?.wtsApNumber,
      loa: e?.data?.loa,
      customerId: e?.data?.id,
      company_address: e?.data?.company_address.trim(),
      deleteIds: e.deleteIds,
    };
    ApiManager.updateCustomer(params, body)
      .then((resp) => {
        if (resp?.data?.status == true) {
          seteditUserModel(false);
          toast("user details updated!");
          getCustomerList();
        } else {
          toast("something went wrong !");
        }
      })
      .catch((error) => console.log("error", error));
  };

  // Delete Company API
  const deleteCompany = () => {
    if (!selectedCustomer) return;
    const body = {
      id: selectedCustomer,
    };

    ApiManager.deleteCompany(body).then((resp) => {
      if (resp?.data?.status == true) {
        toast.success(resp?.data?.message);
        setdeleteModal(false);
        getCustomerList();
      } else {
        toast.error(resp?.data?.message);
      }
    });
  };

  const handleSort = () => {
    setsortWithName(!sortWithName);
    setoldData(customers);
    const sortedArray = [...customers].sort((a, b) =>
      a.customerName.localeCompare(b.customerName)
    );
    setcustomers(sortedArray);
  };

  const handleSort1 = () => {
    setsortWithName(!sortWithName);
    setcustomers(oldData);
  };

  return (
    <div className="flex flex-col h-screen overflow-hidden">
      <CustomHeader />
      <div className="flex h-screen bg-gray-100">
        <SideDrawer />

        {/* ---------------- */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 px-6 py-3 ">
          <div className="text-gray-600 mb-2">
            Dashboard &gt;{" "}
            <span className="text-gray-900 font-semibold">Customers</span>
          </div>
          <p className="text-sm text-gray-600 mb-4">
            Track your projects with ease in just few clicks
          </p>

          <div className="flex flex-col md:flex-row justify-between items-center mb-4 gap-6">
            <div className="bg-white rounded-lg shadow p-4 flex items-center w-full h-30 min-h-[100px] md:min-h-[100px]">
              <div className="mr-4">
                <div className="text-black text-lg font-semibold">
                  Total customers
                </div>
                <div className="text-3xl font-bold">{customers.length}</div>
              </div>
              <div className="ml-auto">
                <MdDashboard size={50} className="text-gray-400" />
              </div>
            </div>

            <div className="bg-white rounded-lg shadow p-6 flex items-center w-full h-30 justify-between min-h-[100px] md:min-h-[100px]">
              <div className="text-black font-semibold text-xl">
                Create new customer
              </div>
              <button
                onClick={() => setopenModal(!openModal)}
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded flex items-center"
              >
                Create <MdAdd size={20} className="ml-1" />
              </button>
            </div>
          </div>

          {/* Customer Table */}
          <span className="font-bold">Customer</span>
          <div className="bg-white rounded-lg shadow-md overflow-x-auto mt-2 mb-24">
            <table className="w-full table-auto min-w-[600px]">
              {" "}
              {/* Minimum width for responsiveness */}
              <thead>
                <tr className="bg-gray-50 text-left text-sm text-gray-500">
                  <th className="p-3 w-20 font-semibold">Sr. No.</th>
                  <th className="p-3 font-semibold">
                    Customer name
                    {sortWithName ? (
                      <ChevronUp
                        onClick={() => handleSort1()}
                        className="inline-block ml-1"
                        size={20}
                      />
                    ) : (
                      <ChevronDown
                        onClick={() => handleSort()}
                        className="inline-block ml-1"
                        size={20}
                      />
                    )}
                  </th>
                  <th className="p-3 w-40 font-semibold">Projects</th>
                  <th className="p-3 w-40 text-center font-semibold">Status</th>
                  <th className="p-3 w-40 font-semibold">View progress</th>
                  <th className="p-3 w-24 font-semibold text-center">
                    Edit user
                  </th>
                </tr>
              </thead>
              <tbody>
                {customers.map((customer: any, index: number) => (
                  <tr key={customer.id} className="border-t border-gray-200">
                    <td className="p-3 text-sm">
                      {String(index + 1).padStart(2, "0")}
                    </td>
                    <td className="p-3 text-base font-medium">
                      {customer.customerName}
                    </td>
                    <td className="p-3 text-base font-medium">
                      {customer.projects}
                    </td>
                    <td className="p-3">
                      <div className="flex items-center justify-center">
                        <div
                          onClick={() => updateCustmerStatus(customer.id)}
                          className={`w-12 h-6 flex items-center rounded-full p-1 duration-300 ease-in-out ${
                            customer.status ? "bg-red-500" : "bg-gray-300"
                          }`}
                        >
                          <div
                            className={`bg-white w-4 h-4 rounded-full shadow-md transform duration-300 ease-in-out ${
                              customer.status ? "translate-x-6" : ""
                            }`}
                          ></div>
                        </div>
                      </div>
                    </td>
                    <div className="flex items-center p-3">
                      <td>
                        <button
                          onClick={() =>
                            navigate("/customerDetails", {
                              state: {
                                customerId: customer.id,
                                customerName: customer.customerName,
                              },
                            })
                          }
                          className="w-full bg-blue-500 hover:bg-blue-600 text-white text-xs py-1 px-2 rounded"
                        >
                          View
                        </button>
                      </td>

                      <td className="p-3">
                        <RiDeleteBin6Line
                          color="red"
                          className="cursor-pointer"
                          onClick={() => {
                            const custID = customer?.id;
                            setdeleteModal(true);
                            setSelectedCustomer(custID);
                          }}
                        />
                      </td>
                    </div>
                    <td className="p-3 px-9 cursor-pointer">
                      <BiSolidEdit
                        size={20}
                        className="text-gray-700"
                        onClick={() => {
                          setcustomerInput({
                            name: customer.customerName,
                            email: customer?.customers.map(
                              (customer: any) => customer.email
                            ),
                            point_of_contact: customer.customers.map(
                              (customer: any) => customer.point_of_contact
                            ),
                            company_address: customer.company_address,
                            id: customer.customers.map(
                              (customer: any) => customer.id
                            ),

                            number: customer.customers.map(
                              (customer: any) => customer.number
                            ),
                            wtsApNumber: customer.customers.map(
                              (customer: any) => customer.watsappNumber
                            ),
                            loa: customer.customers.map(
                              (customer: any) => customer.loa
                            ),
                          }),
                          setcustomerData(customer);
                          seteditUserModel(!editUserModel);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </main>
      </div>

      <CustomerModal
        isOpen={openModal}
        onClose={(e: boolean) => setopenModal(e)}
        onSave={(data: any) => saveCustomerData(data)}
      />
      <ToastContainer />
      <Spinner loading={apiLoader} />

      <EditCustomerModal
        isOpen={editUserModel}
        onClose={(e: boolean) => seteditUserModel(e)}
        onSave={(e: any) => EditUserDetails(e)}
        customerInput={customerInput}
        setcustomerData={(updater: any) =>
          setcustomerInput((prev) => updater(prev))
        }
      />

      <DeleteModal
        isOpen={deleteModal}
        isClose={(e: boolean) => setdeleteModal(e)}
        onSave={() => deleteCompany()}
        text={"Are you sure you want to delete this customer?"}
      />
    </div>
  );
};

export default Dashboard;
