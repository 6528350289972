import { useEffect, useRef, useState } from "react";
import { X } from "lucide-react";

interface customerModal {
  isOpen: boolean;
  onClose: Function;
  onSave: Function;
  customerInput: any;
  setcustomerData: Function;
}

interface CustomerInput {
  name: string;
  email: string[];
  number: string[];
  company_address: string;
  point_of_contact: string[];
  wtsApNumber: string[];
  loa: string[];
}

export default function EditCustomerModal({
  isOpen,
  onClose,
  onSave,
  customerInput,
}: customerModal) {
  const modalRef = useRef<HTMLDivElement>(null);

  const [dummyList, setdummyList] = useState<any>();

  useEffect(() => {
    setdummyList(customerInput);
  }, [customerInput]);

  // validations
  const [addField, setAddField] = useState(false);
  const [deleteIds, setdeleteIds] = useState<any>([]);

  const handleInputChange = <K extends keyof CustomerInput>(
    field: K,
    value: string,
    index?: number
  ) => {
    setdummyList((prevData: CustomerInput) => {
      setAddField(false);
      if (Array.isArray(prevData[field])) {
        const updatedArray = [...(prevData[field] as string[])];
        if (index !== undefined) {
          updatedArray[index] = value;
        } else {
          updatedArray.push(value);
        }
        return { ...prevData, [field]: updatedArray };
      } else {
        return { ...prevData, [field]: value };
      }
    });
  };

  const saveCustomerData = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!dummyList.name || dummyList.name.trim() === "") {
      console.log("1: Name is required");
      setAddField(true);
      return;
    }

    if (
      dummyList.point_of_contact.some(
        (contact: string) => !contact || contact.trim() === ""
      )
    ) {
      console.log("2: Point of contact is required");
      setAddField(true);
      return;
    }

    if (
      dummyList.email.some(
        (email: string) => !email || !emailRegex.test(email.trim())
      )
    ) {
      console.log("3: Valid email is required");
      setAddField(true);
      return;
    }

    if (
      dummyList.number.some((number: string) => !number || number.trim() === "")
    ) {
      console.log("4: Number is required");
      setAddField(true);
      return;
    }

    if (
      dummyList.wtsApNumber.some(
        (wtsApNumber: string) => !wtsApNumber || wtsApNumber.trim() === ""
      )
    ) {
      console.log("5: WhatsApp number is required");
      setAddField(true);
      return;
    }

    onSave({ data: dummyList, deleteIds: deleteIds });
  };

  const customerSelect = (id: number) => {
    setdeleteIds((prev: any) => [...prev, id]);
  };

  const customerDeselect = (id: number) => {
    setdeleteIds((prev: number[]) => prev.filter((item) => item !== id));
  };

  const addNewMember = () => {
    setdummyList((prevData: any) => ({
      ...prevData,
      id: [...prevData.id, 0],
      point_of_contact: [...prevData.point_of_contact, ""],
      email: [...prevData.email, ""],
      number: [...prevData.number, ""],
      wtsApNumber: [...prevData.wtsApNumber, ""],
      loa: [...prevData.loa, ""],
    }));
  };

  const handleRemoveAction = (index: any) => {
    setdummyList((prevData: any) => ({
      ...prevData,
      id: prevData.id.filter((_: any, i: any) => i !== index),
      point_of_contact: prevData.point_of_contact.filter(
        (_: any, i: any) => i !== index
      ),
      email: prevData.email.filter((_: any, i: any) => i !== index),
      number: prevData.number.filter((_: any, i: any) => i !== index),
      wtsApNumber: prevData.wtsApNumber.filter((_: any, i: any) => i !== index),
    }));
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
          onClick={() => onClose(false)}
        />
        <span
          className="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          ref={modalRef}
          className="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all w-full max-w-xl sm:max-w-2xl md:max-w-5xl sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          tabIndex={-1}
        >
          <div className="bg-white  px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                <h3
                  className="text-lg font-medium leading-6 text-gray-900"
                  id="modal-headline"
                >
                  Edit customer details
                </h3>
                <button
                  onClick={() => onClose(false)}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                >
                  <X className="w-5 h-5" />
                </button>

                {/* Form Section */}
                <div className="mt-6 space-y-6">
                  {/* Customer Name */}
                  <div>
                    <label
                      htmlFor="company-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Customer/Company name{" "}
                      <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      id="contact-person"
                      className="mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      placeholder="Enter company name"
                      value={dummyList.name}
                      onChange={(e) => {
                        handleInputChange("name", e.target.value); // No index needed
                      }}
                    />
                  </div>

                  {/* Contact Details */}
                  <div className="grid grid-cols-1 gap-5 sm:grid-cols-5 lg:grid-cols-[repeat(5,_1fr)_0.5fr]">
                    {/* Company Point of Contact */}
                    <div>
                      {dummyList.point_of_contact.map(
                        (item: any, index: number) => (
                          <div className="mb-4">
                            <label
                              htmlFor="contact-person"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Point_of_contact{" "}
                              <span className="text-red-600">*</span>
                            </label>
                            <input
                              key={index}
                              type="text"
                              id={`contact-person-${index}`}
                              className="mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                              placeholder={`Enter Point of contact ${
                                index + 1
                              }`}
                              value={item}
                              onChange={(e) => {
                                handleInputChange(
                                  "point_of_contact",
                                  e.target.value,
                                  index
                                );
                              }}
                            />
                          </div>
                        )
                      )}
                    </div>

                    {/* Email */}
                    <div>
                      {dummyList.email.map((item: any, index: number) => (
                        <div className="mb-4">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email <span className="text-red-600">*</span>
                          </label>
                          <input
                            key={index}
                            type="text"
                            id={`email-${index}`}
                            className="mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            placeholder={`Enter email ${index + 1}`}
                            value={item}
                            onChange={(e) => {
                              handleInputChange("email", e.target.value, index); // Pass index
                            }}
                          />
                        </div>
                      ))}
                    </div>

                    {/* Contact Number */}
                    <div>
                      {dummyList?.number.map((item: any, index: number) => (
                        <div className="mb-4">
                          <label
                            htmlFor="contact-number"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Contact number{" "}
                            <span className="text-red-600">*</span>
                          </label>
                          <input
                            key={index}
                            type="text"
                            id={`contact-person-${index}`}
                            className="mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            placeholder={`Enter number ${index + 1}`}
                            value={item}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (/^\d*$/.test(inputValue)) {
                                handleInputChange("number", inputValue, index);
                              }
                            }}
                          />
                        </div>
                      ))}
                    </div>

                    {/* WhatsApp Number */}
                    <div className="">
                      {dummyList.wtsApNumber.map((item: any, index: number) => (
                        <div className="mb-4">
                          <label
                            htmlFor="whatsApp-number"
                            className=" text-sm font-medium text-gray-700 flex items-center"
                          >
                            WhatsApp Number
                            <img
                              src="https://img.icons8.com/3d-fluency/94/whatsapp-logo.png"
                              alt="WhatsApp logo"
                              className="ml-2"
                              width="20"
                              height="20"
                            />
                          </label>
                          <input
                            key={index}
                            type="text"
                            id={`contact-person-${index}`}
                            className="mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            placeholder={`Enter wtsAppNumber ${index + 1}`}
                            value={item}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (/^\d*$/.test(inputValue)) {
                                handleInputChange(
                                  "wtsApNumber",
                                  inputValue,
                                  index
                                );
                              }
                            }}
                          />
                        </div>
                      ))}
                    </div>

                    <div className="">
                      {dummyList.loa.map((item: any, index: number) => (
                        <div className="mb-4">
                          <label
                            htmlFor="loa"
                            className=" text-sm font-medium text-gray-700 flex items-center"
                          >
                            loa
                            <span className="text-red-600">*</span>
                          </label>
                          <input
                            key={index}
                            type="text"
                            id={`contact-person-${index}`}
                            className="mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            placeholder={`Enter loa`}
                            value={item}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              handleInputChange("loa", inputValue, index);
                            }}
                          />
                        </div>
                      ))}
                    </div>

                    <div className="align-middle items-center pl-3">
                      {dummyList?.id.map((item: any, index: number) => {
                        const isSelected = deleteIds.includes(item);

                        if (item === 0) {
                          return (
                            <button
                              key={index}
                              onClick={() => handleRemoveAction(index)}
                              className="text-sm text-blue-500 text-center mb-4 h-16"
                            >
                              Remove
                            </button>
                          );
                        }

                        return (
                          <button
                            key={index}
                            onClick={() =>
                              isSelected
                                ? customerDeselect(item)
                                : customerSelect(item)
                            }
                            className="text-sm text-red-500 text-center mb-4 h-16"
                          >
                            {isSelected ? "Selected" : "Delete"}
                          </button>
                        );
                      })}
                    </div>
                  </div>

                  <label
                    className="cursor-pointer border px-2 py-1 rounded-md"
                    onClick={() => addNewMember()}
                  >
                    Add new member
                  </label>

                  {/* Company Address */}
                  <div>
                    <label
                      htmlFor="company-address"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Company address <span className="text-red-600">*</span>
                    </label>
                    <textarea
                      id="company-address"
                      rows={3}
                      className="mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      placeholder="Write here"
                      value={dummyList.company_address}
                      onChange={(e) => {
                        handleInputChange("company_address", e.target.value);
                      }}
                    />
                  </div>
                  {addField && (
                    <label className="text-red-600">
                      please enter all the required fields
                    </label>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Submit Button */}

          <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
            <button
              type="button"
              onClick={() => saveCustomerData()}
              className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto sm:text-sm"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
