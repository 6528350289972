import axios from "axios";

export const BASE_URL = "https://vishvasengineering.basenincorp.com/api/";
export const IMG_URL = "https://vishvasengineering.basenincorp.com/";

// client url
// export const BASE_URL = "https://api.vishvaspower.com/api/";
// export const IMG_URL = "https://api.vishvaspower.com/";

// export const BASE_URL = "http://192.168.29.103:3003/api/";
// export const IMG_URL = "http://192.168.29.103:3003/";

// export const BASE_URL = "https://vishvasengineering.reviewdevelopment.net/api/";
// export const IMG_URL = "https://vishvasengineering.reviewdevelopment.net/";

const constructApiRequest = (path: any, method: any, body: any) => ({
  url: path,
  method: method,
  data: body,
});

const Axios = axios.create({
  baseURL: BASE_URL,
  timeout: 20000,
});

const requests = {
  get: (path: any) => Axios(constructApiRequest(path, "get", "")),
  post: (path: any, params: any) =>
    Axios(constructApiRequest(path, "post", params)),
  put: (path: any, params: any) =>
    Axios(constructApiRequest(path, "put", params)),
  delete: (path: any) => Axios(constructApiRequest(path, "delete", "")),
};

// add request path here
const requestPath = {
  // post
  adminLogin: "admin/login",
  createCustomer: "create/customer",
  createProject: "create/project",
  createPhase: "create/phase",
  updatePhase: "update/phase",
  addFeedBack: "add/feeback",
  addFCMToken: "add/fcmToken",
  markAsRead: "markasRead/notification",
  help: "send/help",

  //put
  updateCustomer: "update/customer",

  // get
  getCustomer: "get/all/customer",
  updateStatus: "update/customer/status",
  getProject: "get/project",
  projectDetails: "get/project/details",
  customerlogin: `customer/login`,
  getNotifications: "get/notification",

  // delete
  deleteProject: "delete/project",
  deleteCompany: "delete/company",
  deleteCustomer: "delete/customer",

  sendNotification: "send/notification",
};

const ApiManager = {
  // Admin
  // post api
  adminSignIn: (params: any) => {
    return requests.post(`${requestPath.adminLogin}`, params);
  },
  createCustomer: (params: any) => {
    return requests.post(`${requestPath.createCustomer}`, params);
  },

  createProject: (params: any) => {
    return requests.post(`${requestPath.createProject}`, params);
  },
  createPhase: (params: any) => {
    return requests.post(`${requestPath.createPhase}`, params);
  },
  customerlogin: (params: any) => {
    return requests.post(`${requestPath.customerlogin}`, params);
  },
  addFeedBack: (params: any) => {
    return requests.post(`${requestPath.addFeedBack}`, params);
  },
  addFcmToken: (params: any) => {
    return requests.post(`${requestPath.addFCMToken}`, params);
  },
  markAsRead: (params: any) => {
    return requests.post(`${requestPath.markAsRead}`, params);
  },
  helpApi: (params: any) => {
    return requests.post(`${requestPath.help}`, params);
  },

  // put api
  updateCustomer: (params: any, body: any) => {
    return requests.put(`${requestPath.updateCustomer}/${params.id}`, body);
  },
  updateStatus: (params: any) => {
    return requests.put(`${requestPath.updateStatus}/${params.id}`, "");
  },
  updatePhase: (params: any, projectId: any) => {
    return requests.put(`${requestPath.updatePhase}/${projectId}`, params);
  },

  // delete api
  deleteProject: (params: { id: any }) => {
    return requests.delete(`${requestPath.deleteProject}/${params.id}`);
  },
  deleteCompany: (params: { id: any }) => {
    return requests.delete(`${requestPath.deleteCompany}/${params.id}`);
  },
  deleteCustomer: (id: any) => {
    return requests.delete(`${requestPath.deleteCustomer}/${id}`);
  },

  // get api
  getCustomer: () => {
    return requests.get(`${requestPath.getCustomer}`);
  },
  getProject: (params: { id: any }) => {
    return requests.get(`${requestPath.getProject}/${params.id}`);
  },
  getProjectDetails: (params: { id: any }) => {
    return requests.get(`${requestPath.projectDetails}/${params.id}`);
  },
  getNotification: (userId: any) => {
    return requests.get(`${requestPath.getNotifications}/${userId}`);
  },
  sendNotification: (userId: any) => {
    return requests.get(`${requestPath.sendNotification}/${userId.id}`);
  },
};

export default ApiManager;
